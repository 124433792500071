
import { Component, Vue } from 'vue-property-decorator';
import rulesService from '@/services/rules.service';
import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';

import AlertError from '@/components/alert-error/alert-error.component.vue';
import { UnitTypeSubgroup } from '../../entities/unit-type-subgroup.entity';
import unitTypeSubgroupService from '../../services/uniy-type-subgroup.service';
import companiesService from '../../../Companies/services/companies.service';
import { Company } from '../../../Companies/entities/company.entity';

@Component({ components: { AlertError, DeleteDialog } })
export default class UnitTypeSubgroupCreateComponent extends Vue {
  public $refs: any;

  private unitTypeSubgroup: UnitTypeSubgroup = new UnitTypeSubgroup();

  private errors: any[] = [];

  private showDialogDelete: boolean = false;

  private companies: Company[] = [];

  private status: any[] = [
    {
      id: 0,
      name: 'Inativo',
    },
    {
      id: 1,
      name: 'Ativo',
    },
  ];

  private rules: any = {
    required: rulesService.required,
  };

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private get labelFormType() {
    return this.isEdit ? 'Editar' : 'Cadastrar';
  }

  private get isEdit() {
    const { id } = this.$route.params;
    return !!id;
  }

  private setShowDialogDelete(unitTypeSubgroup: UnitTypeSubgroup) {
    this.showDialogDelete = true;
  }

  private closeDialogDelete() {
    this.showDialogDelete = false;
  }

  private async getUnitTypeSubgroupById() {
    const { id } = this.$route.params;
    const unitTypeSubgroup = await unitTypeSubgroupService.getUnitTypeSubgroupById(id);
    this.unitTypeSubgroup = new UnitTypeSubgroup(unitTypeSubgroup);
    this.$loader.close();
  }

  private async submit() {
    if (!this.$refs.form.validate()) return;
    if (this.isEdit) {
      this.edit();
      return;
    }
    this.save();
  }

  private deleteItem() {
    this.unitTypeSubgroup
      .delete()
      .then(() => {
        this.$snackbar.open({
          text: 'Subgrupo excluido com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.goToList();
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: err.response.data.message,
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(err);
      })
      .finally(() => {
        this.closeDialogDelete();
      });
  }

  private async save() {
    this.unitTypeSubgroup
      .save()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Subgrupo cadastrado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$router.push({
          name: 'unit-type-subgroup-list',
        });
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Erro ao cadastrar Subgrupo',
          color: 'danger',
          buttonColor: '#fff',
        });
        this.errors = err.response.data.errors;
      });
  }

  private async edit() {
    this.unitTypeSubgroup
      .update()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Subgrupo alterado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$router.push({
          name: 'unit-type-subgroup-list',
        });
      })
      .catch((err: any) => {
        this.errors = err.response.data.errors;
      });
  }

  private async goToList() {
    this.$router.push({
      name: 'unit-type-subgroup-list',
    });
  }

  private async getCompanies() {
    const companies = await companiesService.getAllCompanies();
    this.companies = companies.map((company: any) => new Company(company));
  }

  private created() {
    if (this.isEdit) {
      this.getUnitTypeSubgroupById();
    }
    this.getCompanies();
  }
}
